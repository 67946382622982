interface SkillItem {
  group: string;
  image: string;
  subset: string[];
}
const SkillList: SkillItem[] = [
  {
    group: 'JavaScript',
    image: '/',
    subset: [
      'npm',
      'yarn',
      'TypeScript',
      'Angular',
      'Node.js',
      'React.js',
      'Vue.js',
    ],
  },
  {
    group: 'PHP',
    image: '/',
    subset: ['Laravel'],
  },
  {
    group: 'Database',
    image: '/',
    subset: ['MySQL', 'NoSQL'],
  },
  {
    group: 'Version control',
    image: '/',
    subset: ['Git'],
  },
  {
    group: 'HTML',
    image: '/',
    subset: ['HTML5'],
  },
  {
    group: 'CSS',
    image: '/',
    subset: ['CSS3', 'Sass', 'Bootstrap'],
  },
];

export default SkillList;
