import React from 'react';

// @ts-ignore
import Fade from 'react-reveal/Fade';
import SkillList from '../data/skillList';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { useComponentMounted } from '../hooks/componentMounted';

const Skills = () => {
  const { componentMounted } = useComponentMounted();
  const SkillCards = SkillList.map((item, index) => {
    return (
      <Fade
        key={item.group}
        when={componentMounted}
        delay={200 + index * 70}
        duration={200}
        top
      >
        <div className="card">
          <div className="card-body">
            <h5 className="card-title">{item.group}</h5>
            <hr />
            {item.subset.map(skill => (
              <span key={skill} className="badge">
                {skill}
              </span>
            ))}
          </div>
        </div>
      </Fade>
    );
  });
  return (
    <Layout>
      <SEO title="Skills" />
      <div className="skill-content">
        <div className="row">
          <div className="col-md">
            <Fade duration={400} when={componentMounted} top>
              <h2 className="pb-2">Languages & Frameworks</h2>
            </Fade>
            <div className="card-columns">{SkillCards}</div>
          </div>
          <div className="col-md">
            <Fade when={componentMounted} top>
              <h2 className="pb-2">Skill</h2>
            </Fade>
            <Fade when={componentMounted}>
              <p>
                The provided list shows languages and frameworks in which I've
                gained proficiency in the last 2 years working for various
                clients.
              </p>
              <p>
                Click <a href="/work">here</a> if you would like to see some of
                the projects that I've developed for clients from scratch.
              </p>
              <h2>Short background</h2>
              <p>
                Having found out my passion for computers and programming in
                high school, I decided to enroll in a Software Engineering
                course on Faculty of technical Sciences in Novi Sad, Serbia.
                During my 2 years of studies I have expanded my knowledge in
                Data structures and Algorithms.{' '}
              </p>
              <p>
                In-between my 1st and 2nd year of studies, a friend of mine,{' '}
                <a href="https://www.linkedin.com/in/nikola-reljin/">
                  Nikola Reljin
                </a>
                , introduced me to Laravel and web development, after which I
                got seriously commited to web development, and have constantly
                been learning ever since.
              </p>
            </Fade>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Skills;
